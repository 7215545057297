/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CreateLogSchema } from '../../../../schemas/logs.schema';
import {
  Input, Select, Button, DatePicker,
} from '../../../../components/ui';
import { getError } from '../../../../utils/formik';
import {
  baitTypes, fishTypes, fishLogTypes, fishSex, MAX_FISH_WEIGHT, MAX_FISH_LENGTH,
} from '../../../../constants/logs.constants';
import { baitTypeMappings, fishSexMappings, fishTypeMappings } from '../../../../mappings';
import flugurOptions from '../../../../constants/flugur.constants';

import './create.scss';
import { createLog } from '../../../../redux/logs/logs.actions';

const baitTypeOptions = Object.keys(baitTypes).map((k) => ({ value: baitTypes[k], label: baitTypeMappings[baitTypes[k]] }));
const fishSexOptions = Object.keys(fishSex).map((k) => ({ value: fishSex[k], label: fishSexMappings[fishSex[k]] }));
const fishTypeOptions = Object.keys(fishLogTypes).map((k) => ({ value: fishTypes[k], label: fishTypeMappings[fishTypes[k]] }));
const releasedOptions = [{ label: 'Já', value: true }, { label: 'Nei', value: false }];
const nullifiedOptions = [{ label: 'Já', value: true }, { label: 'Nei', value: false }];

const CreateLogForm = ({ id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { area, getLogAreaPending, getLogAreaError } = useSelector((state) => state.logAreas);

  if (getLogAreaPending) return null;
  if (getLogAreaError) return null;

  // eslint-disable-next-line no-underscore-dangle
  const subareasOptions = area.subareas.map((a) => ({ value: a.code, label: a.name, id: a._id }));
  const allSubplaceOptions = [];
  area.subareas.forEach((a, i) => allSubplaceOptions.push(...a.subplaces.map((subP) => ({
    // eslint-disable-next-line no-underscore-dangle
    id: subP._id, value: subP.code, label: `${subP.name} - ${subP.code}`,
  }))));

  const getSubplacesOptions = (subarea) => {
    if (subarea !== null && subarea !== undefined) {
      if (subarea.id !== null && subarea.id !== undefined) {
        return area.subareas.find((s) => s._id === subarea.id).subplaces.map((s) => ({ id: s._id, value: s.code, label: `${s.name} - ${s.code}` }));
      }
    }
    return allSubplaceOptions;
  };

  const getSubareaBySubplaceId = (subplaceId) => {
    if (id !== null && id !== undefined) {
      for (let i = 0; i < area.subareas.length; i++) {
        for (let j = 0; j < area.subareas[i].subplaces.length; j++) {
          const currId = area.subareas[i].subplaces[j]._id;
          if (currId === subplaceId) {
            return {
              id: area.subareas[i]._id,
              code: area.subareas[i].code,
              name: area.subareas[i].name,
            };
          }
        }
      }
    }
    return null;
  };

  return (
    <div className='create-log-wrapper'>
      <Formik
        initialValues={{
          subarea: null,
          subplace: null,
          date: null,
          fishType: '',
          baitType: '',
          baitName: null,
          merkiNr: null,
          sex: '',
          length: undefined,
          weight: undefined,
          comment: null,
          released: null,
          isEmpty: false,
        }}
        enableReinitialize
        validationSchema={CreateLogSchema}
        onSubmit={(values, { resetForm }) => {
          setLoading(true);
          if (values.subarea) {
            delete values.subarea.id;
          }
          if (values.subplace) {
            delete values.subplace.id;
          }
          dispatch(createLog(id, values));
          resetForm();
          setLoading(false);
        }}
      >
        {({
          errors, touched, values, setFieldValue, setFieldTouched, isSubmitting,
        }) => (
          <Form noValidate style={{ width: '100%' }}>
            {!loading && (
              <div className='create-log-wrapper'>
                <DatePicker
                  required
                  label='Dagsetning'
                  value={values.date}
                  onChange={(v) => {
                    setFieldValue('date', v);
                  }}
                  onBlur={() => setFieldTouched('date')}
                  error={getError(errors, touched, 'date')}
                />
                <Select
                  required
                  options={nullifiedOptions}
                  label='Núll skýrsla'
                  // value={nullifiedOptions.find((c) => c.value === values.isEmpty) || null}
                  onChange={({ value }) => {
                    setFieldValue('isEmpty', value);
                    if (value) {
                      setFieldValue('baitType', null);
                      setFieldValue('fishType', null);
                      setFieldValue('sex', null);
                    } else {
                      setFieldValue('baitType', '');
                      setFieldValue('fishType', '');
                      setFieldValue('sex', '');
                    }
                  }}
                  onBlur={() => setFieldTouched('isEmpty')}
                  error={getError(errors, touched, 'isEmpty')}
                />
                {!values.isEmpty && (
                  <>
                    <Select
                      required={!values.isEmpty}
                      options={subareasOptions}
                      label='Undirsvæði'
                      creatable
                      isClearable
                      value={values.subarea ? subareasOptions.find((c) => c.id === values.subarea.id) : ''}
                      onInputChange={(v) => {
                        if (v) {
                          setFieldValue('subarea', { name: v, code: 'CUSTOM', id: null });
                        }
                      }}
                      onChange={(v) => {
                        if (v) {
                          setFieldValue('subarea', { name: v.label, code: v.value, id: v.id });
                          setFieldValue('subplace', null);
                        } else {
                          setFieldValue('subarea', null);
                        }
                      }}
                      onBlur={() => setFieldTouched('subarea')}
                      error={getError(errors, touched, 'subarea')}
                    />
                    <Select
                      required={!values.isEmpty}
                      options={getSubplacesOptions(values.subarea)}
                      label='Veiðistaður'
                      creatable
                      isClearable
                      value={values.subplace ? getSubplacesOptions().find((c) => c.id === values.subplace.id) : ''}
                      onInputChange={(v) => {
                        if (v) {
                          setFieldValue('subplace', { name: v, code: 'CUSTOM', id: v.id });
                        }
                      }}
                      onChange={(v) => {
                        if (v) {
                          setFieldValue('subplace', { name: v.label, code: v.value, id: v.id });
                          if (!values.subarea) {
                            setFieldValue('subarea', getSubareaBySubplaceId(v.id));
                          }
                        } else {
                          setFieldValue('subplace', null);
                        }
                      }}
                      onBlur={() => setFieldTouched('subplace')}
                      error={getError(errors, touched, 'subplace')}
                    />
                    <Select
                      required
                      options={fishTypeOptions}
                      label='Tegund'
                      value={fishTypeOptions.find((c) => c.value === values.fishType)}
                      onChange={({ value }) => {
                        setFieldValue('fishType', value);
                      }}
                      onBlur={() => setFieldTouched('fishType')}
                      error={getError(errors, touched, 'fishType')}
                    />
                    <Select
                      required
                      options={fishSexOptions}
                      label='Kyn'
                      value={fishSexOptions.find((c) => c.value === values.sex)}
                      onChange={({ value }) => {
                        setFieldValue('sex', value);
                      }}
                      onBlur={() => setFieldTouched('sex')}
                      error={getError(errors, touched, 'sex')}
                    />
                    <Input
                      required={!values.length}
                      type='text'
                      label='Þyngd (kg)'
                      max={MAX_FISH_WEIGHT}
                      name='weight'
                      error={getError(errors, touched, 'weight')}
                      value={values.weight}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        const newVal = value.replace(',', '.');
                        const regex = /^\d*(\.\d{0,2})?$/;
                        if (regex.test(newVal) || !newVal) {
                          setFieldValue('weight', newVal);
                        }
                      }}
                    />
                    <Input
                      required={!values.weight}
                      type='text'
                      label='Lengd (cm)'
                      max={MAX_FISH_LENGTH}
                      name='length'
                      error={getError(errors, touched, 'length')}
                      value={values.length}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        const newVal = value.replace(',', '.');
                        const regex = /^\d*(\.\d{0,2})?$/;
                        if (regex.test(newVal) || !newVal) {
                          setFieldValue('length', newVal);
                        }
                      }}
                    />
                    <Select
                      required
                      options={baitTypeOptions}
                      label='Agn'
                      value={fishTypeOptions.find((c) => c.value === values.baitType)}
                      onChange={({ value }) => {
                        setFieldValue('baitType', value);
                      }}
                      onBlur={() => setFieldTouched('baitType')}
                      error={getError(errors, touched, 'baitType')}
                    />
                    <Select
                      options={flugurOptions}
                      label='Nafn agns.'
                      creatable
                      isClearable
                      value={values.baitName ? flugurOptions.find((c) => c.value === values.baitName) : ''}
                      onInputChange={(v) => {
                        if (v) {
                          setFieldValue('baitName', v);
                        }
                      }}
                      onChange={(v) => {
                        if (v) {
                          setFieldValue('baitName', v.value);
                        } else {
                          setFieldValue('baitName', null);
                        }
                      }}
                      onBlur={() => setFieldTouched('baitName')}
                      error={getError(errors, touched, 'baitName')}
                    />
                    <Select
                      required
                      options={releasedOptions}
                      label='Sleppt'
                      value={fishTypeOptions.find((c) => c.value === values.released)}
                      onChange={({ value }) => {
                        setFieldValue('released', value);
                      }}
                      onBlur={() => setFieldTouched('released')}
                      error={getError(errors, touched, 'released')}
                    />
                    <Input
                      label='Nr merkis.'
                      name='merkiNr'
                      error={getError(errors, touched, 'merkiNr')}
                      value={values.merkiNr}
                    />
                    <Input
                      label='Athugasemd'
                      name='comment'
                      error={getError(errors, touched, 'comment')}
                      value={values.comment}
                    />
                  </>
                )}
              </div>
            )}
            <div className='register-fish-button-wrapper'>
              <Button type='submit' block className='register-fish-button' disabled={isSubmitting}>{isSubmitting ? 'Skrái...' : 'Skrá'}</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CreateLogForm.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CreateLogForm;
