import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ChevronLeft from '../../../assets/ChevronLeft.svg';
import Result from '../../../components/result';
import { PageHeader, Select } from '../../../components/ui';
import { years } from '../../../constants/constants';
import routeConstants from '../../../constants/route.constants';
import {
  changeLogAreaYear,
  getLogAreas,
  paginateLogAreas,
} from '../../../redux/log-areas/log-areas.actions';
import './log-areas.scss';

const LogAreasPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    logAreas, getLogAreasPending, getLogAreasError, year,
  } = useSelector(
    (state) => state.logAreas,
  );

  useEffect(() => {
    dispatch(paginateLogAreas(0, 150));
    dispatch(getLogAreas());
  }, [dispatch]);

  const handleYearChange = (newYear) => {
    dispatch(changeLogAreaYear(newYear));
    dispatch(getLogAreas());
  };

  const handleRetry = () => {
    dispatch(getLogAreas());
  };

  if (getLogAreasPending) return null;
  if (getLogAreasError) {
    return (
      <Result.Empty
        title='Villa kom upp'
        message='Gat ekki sótt veiðisvæði'
        buttonText='Reyna aftur'
        onClick={handleRetry}
      />
    );
  }

  if (logAreas.length === 0 && !getLogAreasPending) {
    return (
      <Result.Empty
        title='Engin veiðisvæði'
        message='Ekkert veiddist, fann ekki veiðisvæði'
        buttonText='Til baka'
        onClick={() => history.goBack()}
      />
    );
  }

  return (
    <div>
      <PageHeader title='Veiðibækur' />
      <Select
        required
        options={years.map((item) => ({ value: item, label: item }))}
        label='Ár'
        value={{ label: year, value: year }}
        onChange={({ value }) => {
          handleYearChange(value);
        }}
      />
      <div className='area-link'>
        <div className='row ph-1 jc-sb ai-c area-item'>
          <div className='table-name'>Veiðisvæði</div>
          <div className='table-detail'>
            Lax
            <span className='year'>{` (${year})`}</span>
          </div>
          <div className='table-detail'>
            Bleikja
            <span className='year'>{` (${year})`}</span>
          </div>
          <div className='table-detail'>
            Urriði
            <span className='year'>{` (${year})`}</span>
          </div>
          <div className='table-detail'>
            Hnúðlax
            <span className='year'>{` (${year})`}</span>
          </div>
        </div>
      </div>
      {logAreas.map(({ name, details, id }, i) => (
        <div
          key={id}
          className={`area-link ${i % 2 === 0 && 'blue-background'}`}
        >
          <Link to={`${routeConstants.LOG_AREAS}/${id}`}>
            <div className='row ph-1 jc-sb ai-c area-item'>
              <div className='row ai-c'>
                <div className='table-name'>{name}</div>
                <div className='table-detail'>{details.lax}</div>
                <div className='table-detail'>{details.bleikja}</div>
                <div className='table-detail'>{details.urridi}</div>
                <div className='table-detail'>{details.hnudlax}</div>
              </div>
              <img src={ChevronLeft} alt='left-arrow' />
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default LogAreasPage;
